<template>
  <div class="app-container">
    <div class="page-title">
      <h2>{{ $t('question.questions') }}</h2>
    </div>
    <el-row :gutter="40" class="panel-group">
      <el-col :xs="12" :sm="12" :lg="6" class="card-panel-col">
        <div class="card-panel">
          <div class="card-panel-icon-wrapper icon-people">
            <h1>Q</h1>
          </div>
          <div class="card-panel-description">
            <div class="card-panel-text">
              Total Questions
            </div>
            <count-to :start-val="0" :end-val="list.length" :duration="2600" class="card-panel-num" />
          </div>
        </div>
      </el-col>
    </el-row>
    <el-card v-if="checkRole(['admin'])" class="box-card filter-card just-for-admin">
      <div slot="header" class="clearfix">
        <span>{{ $t('general.filter') }}</span>
        <el-button style="float: right; padding: 3px 0" type="text" @click="initSearch">{{ $t('general.init') }}</el-button>
      </div>
      <div class="filter-container">
        <el-radio v-model="activeList" label="desactives" border @change="getSubQuestionsList('indexdesactive')">{{ $t('general.F_desactives') }}</el-radio>
        <el-radio v-model="activeList" label="disponibles" border @change="handleFilterQuestions">{{ $t('general.disponibles') }}</el-radio>
        <el-radio v-model="activeList" label="corbeille" class="danger-radio" border @change="getSubQuestionsList('indexinvisible')">{{ $t('general.corbeille') }}</el-radio>
      </div>
    </el-card>

    <el-card class="box-card">
      <div class="filter-container">
        <el-input v-model="query.keyword" :placeholder="$t('general.keyword')" class="wd-200 mr-15 filter-item" @keyup.enter.native="handleFilterQuestions" />
        <el-button v-waves class="mr-15 filter-item" type="primary" icon="el-icon-search" @click="handleFilterQuestions">
          {{ $t('general.search') }}
        </el-button>
        <el-button v-waves :loading="downloading" class="filter-item" type="primary" icon="el-icon-download" @click="handleDownload">
          {{ $t('general.export') }}
        </el-button>
        <el-button v-if="checkPermission(['creer question'])" class="mr-15 filter-item" type="primary" icon="el-icon-plus" @click="handlecreateQuestion">
          {{ $t('general.add') }}
        </el-button>

        <el-radio-group v-model="questionFilter" size="medium" fill="#34bfa3">
          <el-radio-button :label="''">All</el-radio-button>
          <el-radio-button :label="'audit'">Audit</el-radio-button>
          <el-radio-button :label="'ci'">Contrôle interne</el-radio-button>
        </el-radio-group>
      </div>
      <el-table v-loading="loading" :data="questionsFilter" border fit highlight-current-row style="width: 100%">
        <!-- el-table-column align="center" label="Code" min-width="100px">
          <template slot-scope="scope">
            <span>{{ scope.row.code }}</span>
          </template>
        </el-table-column -->

        <el-table-column align="center" :label="$t('general.libelle')" min-width="200px">
          <template slot-scope="scope">
            <span>{{ scope.row.libelle }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('general.reference')" min-width="200px">
          <template slot-scope="scope">
            <span>{{ scope.row.reference }}</span>
          </template>
        </el-table-column>

        <el-table-column align="center" :label="$t('categorieQuestion.categorieQuestion')" min-width="150px">
          <template slot-scope="scope">
            <span>{{ libelleCategorieQuestion[scope.row.categorie_question_id] }}</span>
          </template>
        </el-table-column>

        <el-table-column v-if="checkPermission(['activer question'])" align="center" :label="$t('general.status')" width="100">
          <template slot-scope="scope">
            <el-switch
              style="display: block"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="true"
              :inactive-value="false"
              :value="scope.row.is_active"
              @change="setQuestionStatus(scope.row.id,'active')"
            />
          </template>
        </el-table-column>

        <el-table-column v-if="checkRole(['admin']) && (activeList==='corbeille' || query.keyword)" align="center" :label="$t('general.restaurer')" width="100">
          <template slot-scope="scope">
            <el-switch
              v-if="!scope.row.is_visible"
              style="display: block"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="true"
              :inactive-value="false"
              :value="scope.row.is_visible"
              @change="setQuestionStatus(scope.row.id,'visible')"
            />
          </template>
        </el-table-column>

        <el-table-column v-if="checkPermission(['modifier question','visible question','supprimer question'])" align="center" :label="1===1 ? $t('general.actions') : $t('general.suppressionDefinitive')" width="200">
          <template slot-scope="scope">
            <el-button v-if="scope.row.is_visible && checkPermission(['modifier question'])" type="primary" size="small" icon="el-icon-edit" style="margin-right:10px;" @click="handleEditQuestion(scope.row.id);" />
            <el-button v-if="checkPermission(['visible question','supprimer question'])" slot="reference" type="danger" size="small" icon="el-icon-delete" style="margin-right:10px;" @click="handleDeleteQuestion(scope.row.id, scope.row.is_visible, scope.row.libelle);" />
          </template>
        </el-table-column>
      </el-table>

      <pagination v-if="total > 0" :total="total" :page.sync="query.page" :limit.sync="query.limit" @pagination="getQuestionsList" />

      <!-- ======= REGION USER DIALOG ======= -->
      <el-dialog
        :title="editing ? $t('question.edit') + ' ' + currentQuestion.libelle : $t('question.AddQuestion')"
        :visible.sync="dialogCreateQuestion"
        :before-close="handleCloseCampagneagricoleCreateDraw"
        :loading="questionCreating"
        width="30%"
      >
        <div class="content-inputs">
          <el-radio-group v-model="ciQuestion" size="medium">
            <el-radio-button :label="'audit'">Audit</el-radio-button>
            <el-radio-button :label="'ci'">Contrôle interne</el-radio-button>
          </el-radio-group>
          <el-form ref="questionForm" :rules="rules" :model="currentQuestion" label-position="left">
            <div class="ligne">
              <el-form-item :label="$t('categorieQuestion.categorieQuestion')" prop="categorie_question_id">
                <el-select v-model="currentQuestion.categorie_question_id" class="filter-item" :placeholder="$t('categorieQuestion.categorieQuestion')" style="width: 100%;" prop="categorie_question_id">
                  <el-option
                    v-for="(categorieQuestion, idex) in categoriesFiltrees"
                    :key="idex"
                    :label="categorieQuestion.libelle | uppercaseFirst"
                    :value="categorieQuestion.id"
                    :disabled="!categorieQuestion.is_active"
                  />
                </el-select>
              </el-form-item>
              <el-button class="bouton" type="primary" :icon="dialogCreateCategorieQuestion ? 'el-icon-minus' : 'el-icon-plus'" circle @click="dialogCreateCategorieQuestion=!dialogCreateCategorieQuestion" />
            </div>
            <el-form-item :label="$t('general.libelle')" prop="libelle">
              <el-input v-model="currentQuestion.libelle" />
            </el-form-item>
            <el-form-item :label="$t('general.reference')" prop="reference">
              <el-input v-model="currentQuestion.reference" />
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <div style="text-align:right;">
            <el-button plain type="danger" @click="dialogCreateQuestion=false">
              {{ $t('permission.cancel') }}
            </el-button>
            <el-button type="primary" :loading="questionCreating" @click="editing ? updateQuestion() : createQuestion()">
              {{ questionCreating ? $t('general.saving') : $t('general.save') }}
            </el-button>
          </div>
        </span>
      </el-dialog>

      <el-dialog
        :title="$t('categorieQuestion.AddCategorieQuestion')"
        :visible.sync="dialogCreateCategorieQuestion"
        :before-close="handleCloseCampagneagricoleCreateDraw"
        :loading="categorieQuestionCreating"
        width="30%"
      >
        <div class="content-inputs">
          <el-form ref="categorieQuestionForm" :rules="rules" :model="currentCategorieQuestion" label-position="left">
            <el-form-item :label="$t('categorieQuestion.libelle')" prop="libelle">
              <el-input v-model="currentCategorieQuestion.libelle" />
            </el-form-item>
            <el-form-item :label="$t('general.description')" prop="description">
              <el-input v-model="currentCategorieQuestion.description" type="textarea" />
            </el-form-item>
          </el-form>
          <el-radio-group v-model="ci" size="medium">
            <el-radio-button :label="'audit'">Audit</el-radio-button>
            <el-radio-button :label="'ci'">Contrôle interne</el-radio-button>
          </el-radio-group>
        </div>
        <span slot="footer" class="dialog-footer">
          <div style="text-align:right;">
            <el-button plain type="danger" @click="dialogCreateCategorieQuestion=false">
              {{ $t('permission.cancel') }}
            </el-button>
            <el-button type="primary" :loading="categorieQuestionCreating" @click="editing ? updateCategorieQuestion() : createCategorieQuestion()">
              {{ categorieQuestionCreating ? $t('general.saving') : $t('general.save') }}
            </el-button>
          </div>
        </span>
      </el-dialog>

      <!-- ======= REGION USER DIALOG ======= -->

    </el-card>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'; // Pagination
import Resource from '@/api/resource';
import waves from '@/directive/waves'; // Directives
import permission from '@/directive/permission'; //  directive Permission
import role from '@/directive/role'; // Directives Role
import checkPermission from '@/utils/permission'; // Vérification Permission
import checkRole from '@/utils/role'; // Vérification Roles
import moment from 'moment';
import CountTo from 'vue-count-to';
const categorieQuestionResource = new Resource('categorie_questions');
const questionResource = new Resource('questions');
export default {
  name: 'QuestionsList',
  components: { Pagination, CountTo },
  directives: { waves, permission, role },
  data() {
    return {
      categorieQuestions: [],
      libelleCategorieQuestion: [],
      ciQuestion: '',
      questionFilter: '',
      ci: '',
      list: null,
      total: 0,
      loading: true,
      activeList: 'disponibles',
      downloading: false,
      questionCreating: false,
      categorieQuestionCreating: false,
      currentCategorieQuestion: {},
      editing: false,
      currentQuestion: {},
      query: {
        page: 1,
        limit: 100,
        keyword: '',
      },
      dialogCreateQuestion: false,
      dialogCreateCategorieQuestion: false,
      currentQuestionId: 0,
      rules: {
        libelle: [{ required: true, message: this.$t('question.LibelleRequired'), trigger: 'blur' }],
        categorie_question_id: [{ required: true, message: this.$t('question.CategorieQuestionRequired'), trigger: 'blur' }],
      },
      fileList: [],
    };
  },
  computed: {
    categoriesFiltrees() {
      if (this.ciQuestion.length === 0) {
        return this.categorieQuestions;
      }
      return this.categorieQuestions.filter(catQuest => catQuest.ci === (this.ciQuestion === 'ci'));
    },
    questionsFilter() {
      if (this.questionFilter.length === 0) {
        return this.list;
      }
      const filtedCategories = this.categorieQuestions.filter(catQuest => catQuest.ci === (this.questionFilter === 'ci'));
      const filtedCategoriesIds = filtedCategories.map(cat => cat.id);
      return this.list.filter(quest => filtedCategoriesIds.includes(quest.categorie_question_id));
    },
  },
  created() {
    this.resetNewQuestion();
    this.getQuestionsList();
    this.getCategoriesQuestionsList();
  },
  methods: {
    checkPermission,
    checkRole,
    isEmptyArray(array){
      if (typeof array !== 'undefined' && array.length === 0) {
        return true;
      }
    },
    handleCloseCampagneagricoleCreateDraw(done) {
      this.$confirm(this.$t('general.closingWarningText'))
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    cancelquestionForm() {
      this.$refs.CampagneagricoleCreationDrawer.closeDrawer();
    },
    async setQuestionStatus(id, type){
      const data = await questionResource.get('toogle_' + type + '/' + id);
      if (data.success){
        this.$message({
          type: 'success',
          message: this.$t('general.statusUpdated'),
        });
        this.initSearch();
      } else {
        this.$message({
          type: 'danger',
          message: this.$t('general.statusUpdateError'),
        });
      }
    },
    initSearch(){
      this.handleFilterQuestions();
    },
    async getQuestionsList() {
      this.loading = true;
      const { data, meta } = await questionResource.list(this.query);
      this.list = data.reverse();
      this.total = meta !== undefined ? meta.total : 0;
      console.log('===== EDI');
      console.log(data);
      console.log('===== EVO');
      this.loading = false;
    },
    async getSubQuestionsList(subUrl) {
      this.loading = true;
      const { data } = await questionResource.sublist('/' + subUrl, this.query);
      this.list = data;
      this.loading = false;
    },
    handleFilterQuestions() {
      this.query.page = 1;
      this.getQuestionsList();
    },
    handlecreateQuestion() {
      this.editing = false;
      this.resetNewQuestion();
      this.dialogCreateQuestion = true;
      this.$nextTick(() => {
        this.$refs['questionForm'].clearValidate();
      });
    },
    handleEditQuestion(id){
      this.editing = true;
      this.resetNewQuestion();
      this.currentQuestion = this.list.find(abonne => abonne.id === id);
      this.dialogCreateQuestion = true;
      this.$nextTick(() => {
        this.$refs['questionForm'].clearValidate();
      });
    },
    handleDeleteQuestion(id, visible, libelle) {
      console.log('id : ' + id + 'libelle : ' + libelle);
      this.$confirm(this.$t('question.deleteWarn') + ' ' + libelle + '. ' + this.$t('general.deleteContinue'), 'Warning', {
        confirmButtonText: 'Oui',
        cancelButtonText: this.$t('general.cancel'),
        type: 'warning',
      }).then(() => {
        console.log('result : ');
        if (!visible && (checkRole(['admin']) || checkPermission(['supprimer question']))) {
          console.log('result 2 : ');
          this.$swal({
            title: this.$t('general.SureQuestion'),
            text: this.$t('general.irrversibleMessage'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this.$t('general.ouiSupprimer'),
          }).then((result) => {
            if (result.isConfirmed) {
              questionResource.destroy(id).then(response => {
                this.$message({
                  type: 'success',
                  message: this.$t('question.suppressionEffectue'),
                });
                this.initSearch();
              }).catch(error => {
                console.log(error);
              });
            }
          });
        } else {
          this.setQuestionStatus(id, 'visible');
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('general.suppressionAnnule'),
        });
      });
    },
    createQuestion() {
      this.$refs['questionForm'].validate((valid) => {
        if (valid) {
          this.questionCreating = true;
          questionResource
            .store(this.currentQuestion)
            .then(async(response) => {
              this.$message({
                message: this.$t('question.NewQuestion') + ' ' + this.currentQuestion.libelle + ' ' + this.$t('question.iscreatedSuccessfully'),
                type: 'success',
                duration: 5 * 1000,
              });
              this.currentQuestionId = response.data.id;
              this.resetNewQuestion();
              this.dialogCreateQuestion = false;
              this.handleFilterQuestions();
            })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.questionCreating = false;
            });
        } else {
          console.log('Erreur !!');
          return false;
        }
      });
    },
    resetNewCategorieQuestion() {
      this.currentCategorieQuestion = {
        libelle: '',
        description: '',
      };
    },
    createCategorieQuestion() {
      this.$refs['categorieQuestionForm'].validate((valid) => {
        if (valid && this.ci.length > 0) {
          this.categorieQuestionCreating = true;
          this.currentCategorieQuestion.ci = this.ci !== 'audit';
          categorieQuestionResource
            .store(this.currentCategorieQuestion)
            .then(async(response) => {
              this.$message({
                message: this.$t('categorieQuestion.NewCategorieQuestion') + ' ' + this.currentCategorieQuestion.libelle + ' ' + this.$t('categorieQuestion.iscreatedSuccessfully'),
                type: 'success',
                duration: 5 * 1000,
              });
              console.log('LA NOULEEE : ', response.data.id);
              this.currentQuestion.categorie_question_id = response.data.id;
              this.resetNewCategorieQuestion();
              this.dialogCreateCategorieQuestion = false;
              this.handleFilterCategorieQuestions();
            })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.categorieQuestionCreating = false;
              this.dialogCreateCategorieQuestion = false;
              this.getCategoriesQuestionsList();
            });
        } else {
          this.$message({
            type: 'info',
            message: this.$t('general.formInvalid'),
          });
          return false;
        }
      });
    },
    updateQuestion() {
      this.$refs['questionForm'].validate((valid) => {
        if (valid) {
          this.questionCreating = true;
          questionResource.update(this.currentQuestion.id, this.currentQuestion).then(response => {
            this.resetNewQuestion();
            this.handleFilterQuestions();
            this.$message({
              message: this.$t('question.isupdatedSuccessfully'),
              type: 'success',
              duration: 5 * 1000,
            });
            this.dialogCreateQuestion = false;
            this.editing = false;
          })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.questionCreating = false;
            });
        } else {
          console.log('Erreur !!');
          return false;
        }
      });
    },
    resetNewQuestion() {
      this.currentQuestion = {
        libelle: '',
        reference: '',
        categorie_question_id: null,
      };
    },
    handleDownload() {
      this.downloading = true;
      import('@/vendor/Export2Excel').then(excel => {
        const tHeader = ['ID', this.$t('general.code'), this.$t('question.libelle')];
        const filterVal = ['id', 'code', 'libelle'];
        const data = this.formatJson(filterVal, this.list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: 'Liste des questions SCIAGRI_' + moment().format('DD-MM-YYYY_hh-mm'),
        });
        this.downloading = false;
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]));
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    onChange(file, fileList){
      var a = this.fileList;
      a.push({ name: file.name, url: file.url });
      // console.log('onChange begin');
      this.fileList = a;
      // console.log(this.fileList);
      // console.log('onChange end');
    },
    async getCategoriesQuestionsList() {
      const { data } = await categorieQuestionResource.list(this.query);
      this.categorieQuestions = data;
      const _reg = [];
      data.forEach(categorieQuestion => {
        _reg[categorieQuestion.id] = categorieQuestion.libelle;
      });
      this.libelleCategorieQuestion = _reg;
    },
  },
};
</script>

<style lang="scss" scoped>
.panel-group {
  margin-top: 18px;

  .card-panel-col {
    margin-bottom: 32px;
  }

  .card-panel {
    height: 108px;
    cursor: pointer;
    font-size: 12px;
    position: relative;
    overflow: hidden;
    color: #666;
    background: #fff;
    box-shadow: 4px 4px 40px rgba(0, 0, 0, .05);
    border-color: rgba(0, 0, 0, .05);

    &:hover {
      .card-panel-icon-wrapper {
        color: #fff;
      }

      .icon-people {
        background: #40c9c6;
      }

      .icon-message {
        background: #36a3f7;
      }

      .icon-money {
        background: #f4516c;
      }

      .icon-shopping {
        background: #34bfa3
      }
    }

    .icon-people {
      color: #40c9c6;
    }

    .icon-message {
      color: #36a3f7;
    }

    .icon-money {
      color: #f4516c;
    }

    .icon-shopping {
      color: #34bfa3
    }

    .card-panel-icon-wrapper {
      float: left;
      margin: 14px 0 0 14px;
      padding: 16px;
      transition: all 0.38s ease-out;
      border-radius: 6px;
    }

    .card-panel-icon {
      float: left;
      font-size: 48px;
    }

    .card-panel-description {
      float: right;
      font-weight: bold;
      margin: 26px;
      margin-left: 0px;

      .card-panel-text {
        line-height: 18px;
        color: rgba(0, 0, 0, 0.45);
        font-size: 16px;
        margin-bottom: 12px;
      }

      .card-panel-num {
        font-size: 20px;
      }
    }
  }
}

@media (max-width:550px) {
  .card-panel-description {
    display: none;
  }

  .card-panel-icon-wrapper {
    float: none !important;
    width: 100%;
    height: 100%;
    margin: 0 !important;

    .svg-icon {
      display: block;
      margin: 14px auto !important;
      float: none !important;
    }
  }
}
.ligne {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.bouton {
  height: 40px;
  width: 40px;
  margin-right: auto;
  margin-left: 15px;
  margin-top: 10px;
}
.edit-input {
  padding-right: 100px;
}
.cancel-btn {
  position: absolute;
  right: 15px;
  top: 10px;
}
.dialog-footer {
  text-align: left;
  padding-top: 0;
  margin-left: 150px;
}
.app-container {
  flex: 1;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
  .block {
    float: left;
    min-width: 250px;
  }
  .clear-left {
    clear: left;
  }
}
</style>
